var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      staticClass: "hide-ok-button",
      attrs: { visible: _vm.visible, title: "Add a new Card", "ok-text": "" },
      on: { cancel: _vm.close },
    },
    [
      _vm.visible
        ? _c("stripe-card", {
            attrs: { stripe: _vm.stripePublicKey, options: {} },
            on: { token: _vm.saveCard },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "stripe-footer",
          staticStyle: { "padding-left": "8px", "padding-right": "8px" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { staticClass: "gx-text-left", attrs: { xs: 10, sm: 6 } },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/ssl_rapid_secured.gif") },
                  }),
                ]
              ),
              _c(
                "a-col",
                {
                  staticStyle: { "text-align": "right" },
                  attrs: { xs: 14, sm: 18 },
                },
                [
                  _c("a-button", { on: { click: _vm.close } }, [
                    _vm._v("Cancel"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }